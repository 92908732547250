// @ts-nocheck
// The file is provisional，don't depend on it

import React from 'react'
import { IContext } from '@zh-mix/core-react'

let STORE_CONTEXT: React.Context<IContext<any>>
if (__isBrowser__) {
  STORE_CONTEXT = window.STORE_CONTEXT || React.createContext<IContext>({
    state: {}
  })
  window.STORE_CONTEXT = STORE_CONTEXT
} else {
  STORE_CONTEXT = React.createContext<IContext>({
    state: {}
  })
}

export {
  STORE_CONTEXT
}
