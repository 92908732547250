import {useEffect} from 'react'

import {useIsOffice} from '~/libs/envContext'
import loadScriptAsync from '~/utils/loadScriptAsync'
let isLoaded = false

const VConsole = () => {
  const isOffice = useIsOffice()
  useEffect(() => {
    if (!isLoaded) {
      if (isOffice) {
        void loadScriptAsync(
          'https://unpkg.com/vconsole@3.13.0/dist/vconsole.min.js'
        )
          .then(() => {
            // https://github.com/Tencent/vConsole
            new window.VConsole()
            isLoaded = true
          })
          .catch((err: unknown) => {
            console.log('vConsole 初始化失败')
            console.log(err)
          })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default VConsole
