import {useContext, useEffect} from 'react'
import zhihuHybrid from 'zhihu-hybrid'

import {ENV_CONTEXT, Theme, UA, IEnvState} from './context'
import {setHtmlTheme} from './utils'

const THEMES = ['light', 'dark']

const useEnvContext = () => {
  const {envState} = useContext(ENV_CONTEXT)
  return envState as IEnvState
}

const useSetEnvContext = () => {
  const {setEnvState, envState} = useContext(ENV_CONTEXT)

  return (state: Partial<IEnvState>) => {
    if (setEnvState && envState) {
      setEnvState({
        ...envState,
        ...state,
      })
    }
  }
}

export const useUA = (): UA => {
  const context = useEnvContext()
  return context.ua
}

export const useIsOffice = (): boolean => {
  const context = useEnvContext()
  return context.isOffice
}

interface SetTheme {
  (theme: Theme): void
}

const useThemeListen = (setTheme: SetTheme) => {
  const ua = useUA()

  useEffect(() => {
    const darkMediaQuery =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')

    let hybridThemeUnlisten: () => void

    const changeTheme = (theme: Theme) => {
      if (THEMES.includes(theme)) {
        setTheme(theme)
      }
    }

    const onWebchangeTheme = (event: MediaQueryListEvent) =>
      changeTheme(event?.matches ? 'dark' : 'light')

    ua.Zhihu &&
      zhihuHybrid.supportsEvent('base/themeChange').then(supported => {
        if (supported) {
          hybridThemeUnlisten = zhihuHybrid('base/themeChange').listen(
            ({theme}) => {
              changeTheme(theme)
            }
          )
        }
      })

    if (!ua.Zhihu) {
      try {
        darkMediaQuery?.addEventListener('change', onWebchangeTheme) // Chrome & Firefox
      } catch (e1) {
        try {
          darkMediaQuery?.addListener(onWebchangeTheme) // Safari
        } catch (e2) {
          /** noop */
        }
      }
    }

    return () => {
      typeof hybridThemeUnlisten === 'function' && hybridThemeUnlisten()

      if (!ua.Zhihu) {
        try {
          darkMediaQuery?.removeEventListener('change', onWebchangeTheme)
        } catch (e1) {
          try {
            darkMediaQuery?.removeListener(onWebchangeTheme)
          } catch (e2) {
            /** noop */
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useTheme = (): [Theme, SetTheme] => {
  const setContext = useSetEnvContext()

  const setTheme: SetTheme = (theme: Theme) => {
    if (THEMES.includes(theme)) {
      setHtmlTheme(theme)
      setContext({theme})
    }
  }

  useThemeListen(setTheme)

  const context = useEnvContext()
  return [context.theme || 'light', setTheme]
}
