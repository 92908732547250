import parseUA from 'zhihu-utilities/lib/ua'

import type {ISSRContext} from '@zh-mix/types'

import {Theme} from './context'
import {safeJSONParse, getQueryString} from './utils'

interface IHybridConfig {
  theme: string
}

function getHybridConfig(ctx?: ISSRContext) {
  let hybridConfig = null

  if (__isBrowser__) {
    // NOTE: 尝试客户端获取
    // http://hybrid.in.zhihu.com/api/base/getHybridConfig
  } else if (ctx?.request) {
    hybridConfig = safeJSONParse<IHybridConfig>(
      ctx.request.get('x-hybrid-config')!
    )
  }

  return hybridConfig
}

export function getTheme(ctx?: ISSRContext): Theme {
  let theme = 'light'

  if (__isBrowser__) {
    const isDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    const queryTheme = getQueryString('theme')
    theme = queryTheme || (isDark ? 'dark' : 'light')
  } else if (ctx?.request) {
    const hybridConfig = getHybridConfig(ctx)
    theme = (
      (ctx.request.query.theme as string) ||
      hybridConfig?.theme ||
      ctx.request.get('x-app-theme') ||
      ''
    ).toLowerCase()
  }

  if (theme !== 'dark') {
    theme = 'light'
  }
  return theme as Theme
}

/**
 * 获取 ua 对象，浏览器通过 window 获取，node 端通过 ctx 获取
 * @param ctx 服务端 ctx
 * @returns 解析的 ua 对象
 */
export function getParseUA(ctx?: ISSRContext) {
  let userAgent = ''

  if (__isBrowser__) {
    userAgent = window.navigator.userAgent
  } else if (ctx?.request) {
    userAgent =
      ctx.request.get('x-app-ua') || ctx.request.get('user-agent') || ''
  }

  return parseUA(userAgent)
}

export function getIsOffice(ctx?: ISSRContext) {
  let isOffice = false
  if (__isBrowser__) {
    isOffice = false
  } else if (ctx?.request) {
    isOffice =
      (ctx.request.get('x-traffic-source') || '').toLowerCase() === 'office'
  }
  return isOffice
}
