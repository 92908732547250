import {createContext, useState, useRef} from 'react'
import parseUA from 'zhihu-utilities/lib/ua'

import type {ISSRContext} from '@zh-mix/types'
import type {LayoutProps} from '@zh-mix/core-react'

import {getParseUA, getTheme, getIsOffice} from './helper'
import {setInjectData, getInjectData, injectDataId} from './utils'
export type Theme = 'light' | 'dark'
export type UA = ReturnType<typeof parseUA>
export interface IEnvState {
  ua: UA
  theme: Theme
  isOffice: boolean
}

interface IEnvContext {
  envState: IEnvState
  setEnvState: React.Dispatch<React.SetStateAction<IEnvState>>
}

export const initEnvContext = (ctx?: ISSRContext): IEnvState => {
  return {
    ua: getParseUA(ctx),
    theme: getTheme(ctx),
    isOffice: getIsOffice(ctx),
  }
}

export const ENV_CONTEXT = createContext<Partial<IEnvContext>>({})

export function EnvContext(props: LayoutProps) {
  const {ctx} = props

  const initialState = useRef(
    Object.assign(
      {},
      initEnvContext(ctx),
      __isBrowser__ ? getInjectData() : null
    )
  )

  const [envState, setEnvState] = useState(initialState.current)

  return (
    <ENV_CONTEXT.Provider value={{envState, setEnvState}}>
      {props.children}

      <textarea
        id={injectDataId}
        hidden
        readOnly
        value={setInjectData(initialState.current)}
      />
    </ENV_CONTEXT.Provider>
  )
}
