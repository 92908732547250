import serialize from 'serialize-javascript'

import {Theme} from './context'

export function safeJSONParse<T>(str: string): T | null {
  try {
    return JSON.parse(str) as T
  } catch (e) {
    return null
  }
}

export function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substring(1).match(reg)

  if (r !== null) {
    return decodeURIComponent(r[2])
  }
  return null
}

export const injectDataId = 'envContext'

export function getInjectData() {
  const element = document.getElementById(injectDataId)

  if (element?.innerText) {
    return safeJSONParse(element.innerText)
  }

  return null
}

export function setInjectData(data: unknown) {
  return serialize(data)
}

export function setHtmlTheme(theme: Theme) {
  document.documentElement.setAttribute('data-theme', theme)
}
