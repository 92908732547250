
      // The file is provisional，don't depend on it 
        export const FeRoutes = [{"path":"/","component": function dynamicComponent () {
            return import(/* webpackChunkName: "index" */ '@/pages/index/render.tsx')
          }
          ,"webpackChunkName":"index"}] 
        export { default as App } from "@/components/layout/App.tsx"
        
        
        
        
        export const PrefixRouterBase='/'
        //#not-import-react-router-dom
        