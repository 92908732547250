import loadScript from 'load-script'

const loadScriptAsync = (url: string) => {
  return new Promise<HTMLScriptElement>((resolve, reject) => {
    loadScript(url, (err, script) => {
      if (err) {
        reject(err)
      } else {
        resolve(script)
      }
    })
  })
}

export default loadScriptAsync
