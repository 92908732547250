import '~/utils/polyfill'
import '~/styles/modern-normalize.css'

import {FC} from 'react'
import {ZAProvider} from 'za-react'

import VConsole from '~/utils/vConsole'
import {EnvContext} from '~/libs/envContext'

import type {LayoutProps} from '@zh-mix/core-react'
import {sentryBrowserInit} from '@zh-mix-mkt/trace/sentry'

if (__isBrowser__) {
  const sentry = sentryBrowserInit({
    dsn: 'https://xxxxxxxx@crash2.zhihu.com/123456',
    release: process.env.ARTIFACT_TAG!,
    enabled: __isProd__,
  })

  sentry.setTags({
    app_name: process.env.APP_NAME,
    target: 'client',
  })
}

const App: FC<LayoutProps> = props => {
  return (
    <EnvContext {...props}>
      <ZAProvider sdkVersion="4.19.1">
        {props.children!}
        <VConsole />
      </ZAProvider>
    </EnvContext>
  )
}

export default App
